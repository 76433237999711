import React,{useState,useEffect} from "react";
import "./FormStyle.scss";
import { useForm } from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message";

import {motion} from "framer-motion";
import {AnimatePresence} from "framer-motion";

import apiCall from "../../../APIcall.js"

import done from "../../../Images/doneFormIcon.svg"
import error from "../../../Images/errorFormIcon.svg"

export default function Form() {
    const [send, setSend] = useState(false)
    const [success, setSuccess] = useState(null)
    const { register, formState:{ errors}, handleSubmit } = useForm();
    const onSubmit = async (data) =>{
        try{
            await apiCall(data)
            setSuccess(true)
            setSend(true)
        }
        catch (e) {
            setSuccess(false)
            setSend(true)
        }
    };


    return(
        <>
            <AnimatePresence mode="wait">
            {send ? <motion.div
                    className="form__send"
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    transition={{
                        duration: 0.5,
                    }}
                >
                <img className="done__form" src={ success ? done : error} alt="done icon"/>
                <h2>{success ? "Thank you for your request" : "Something went wrong!"}</h2>
                <p>{success ? "We will contact you as soon as possible":"Check your connection or try another way"}</p>
            </motion.div>
                :
            <motion.form
                className="form__page"
                onSubmit={handleSubmit(onSubmit)}
                key="form"
                initial={{opacity:1 , y:0}}
                exit={{opacity:0 , y:-500}}
                transition={{
                    duration: 0.5,
                }}
            >
                <div className="form__input">
                    <label>Full name</label>
                    <input type="text" id="name" placeholder="Name Surname" {...register("name",{
                        required:"This field is required",
                        minLength:{value:4, message:"Full name must be at least 4 characters long"},
                        maxLength:{value:60, message:"Full name must be less than 60 characters long"},
                        pattern:{value:/^[A-Za-z]+\s[A-Za-z]+$/,message:"Name can include only letters"}})}/>
                    <ErrorMessage errors={errors} name="name" render={({message})=><p className="error" >{message}</p>} />
                </div>
                <div className="form__input">
                    <label>Email</label>
                    <input id="mail" type="text" placeholder ="exapmple@mail.com" {...register("mail",{
                        required:"This field is required",
                        minLength:{value:4, message:"Email must be at least 4 symbols long"},
                        maxLength:{value:60, message:"Email must be less than 60 characters long"},
                        pattern:{value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,message:"Email is invalid"}})}/>
                    <ErrorMessage errors={errors} name="mail" render={({message})=><p className="error" >{message}</p>} />
                </div>
                <div className="form__input">
                    <label>Phone number</label>
                    <input id="number" type="phone" placeholder="+1-123-456-7890" {...register("phone",{
                        required:"This field is required",
                        minLength:{value:10, message:"Number is to short"},
                        maxLength:{value:14, message:"Number is to long"},})}/>
                    <ErrorMessage errors={errors} name="phone" render={({message})=><p className="error">{message}</p>} />
                </div>
                <div className="form__input">
                    <label>Message(additional)</label>
                    <textarea id="message" placeholder="Your message" {...register("text",{
                        required:false,
                        maxLength:{value:500, message:"Message must be less than 500 characters long"},
                    })}/>
                    <ErrorMessage errors={errors} name="text" render={({message})=><p className="error">{message}</p>} />
                </div>
                <div className="form__input__submit">
                    <span className="form__input__test">
                    <label>5+3=?</label>
                    <input type="text" placeholder="Answer" id="test" {...register("test",{
                        required:"This field is required",
                        pattern:{value:/^[8]$/,message:"Answer is incorect"}
                    })} />
                         <ErrorMessage errors={errors} name="test" render={({message})=><p className="error error__test">{message}</p>} />
                    </span>
                    <button className="submit-button" type="submit">Send</button>
                </div>
            </motion.form>
            }
            </AnimatePresence>
        </>
    )
}