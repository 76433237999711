
const comments=[
    {
        id:0,
        name:"Jenny M",
        stars:"5",
        text:"Our fridge started functioning inappropriately. I noticed strange noise and therefore call Appliance Repair. Qualified specialists found a problem and replaced necessary component"
    },
    {
        id:1,
        name:"Jason",
        stars:"5",
        text:"Extremely responsive and professional service. An absolute pleasure. I highly recommend this company. They had all the necessary parts in his truck. The intelligence board blew during installation"
    },
    {
        id:2,
        name:"Victoria M.",
        stars:"4",
        text:"Our refrigerator had started leaking, and we were worried about potential damage. The repair person was knowledgeable and diagnosed the issue swiftly. They replaced a faulty valve, and the fridge is back to working perfectly. Thank you for the prompt and effective service!"
    } ,
    {
        id:3,
        name:"Nathan L.",
        stars:"3",
        text:"Good!But expensive!"
    },
    {
        id:4,
        name:"Amanda B.",
        stars:"4",
        text:"Quick Response, Quality Work!"
    }


]


export default comments;