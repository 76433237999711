import React from "react";

import "./FooterStyle.scss";


export default function Footer() {
    return(
        <footer className="footer">
            <div className="footer__container">
                <h1> Contact us !</h1>
                <p>+1-347-510-2738</p>
                <p>vikingsubzero.service@gmail.com</p>
            </div>
        </footer>
    )
}